.loader {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.accomplishedProjectDetails {
  display: flex;
  margin: 48px 200px 100px 200px;


  & .left {
    display: flex;
    flex-direction: column;
    min-width: 500px;
    height: auto;
    max-width: 500px;

    & .image {
      width: 500px;
      height: 500px;

      &>img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }


  & .right {
    width: 100%;
    display: flex;
    margin-left: 64px;
    flex-direction: column;
    justify-content: space-between;
    
    &>.data {
      margin-bottom: 16px;
      & .date {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #BFC2D1;
      }

      & .title {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        color: #000000;
      }

      & .description {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #000000;
      }
    }

    &>.buttons {
      display: flex;
      flex-direction: column;
      & > .next {
        margin-bottom: 16px;
      }
    }

  }
}