.welcome {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    color: black;
    height: 900px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;

    &>.welcome_logo {
        background-image: url('../../images/welcome_logo.png');
        min-width: 200px;
        min-height: 200px;
        background-size: contain;
        margin-top: 100px;
        margin-bottom: 20px;
        background-position: center;
        background-repeat: no-repeat;

    }

    &>.communication {
        font-size: 64px;
        line-height: 96px;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
        margin: 0 150px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 5;
        margin-bottom: 74px;

        &>.text {
            font-size: 17px;
            font-weight: 400;
            line-height: 26px;
            font-style: normal;
            text-align: center;
            text-transform: none;
            width: 70%;
            color: #BFC2D1;
            margin-top: 24px;
            margin-bottom: 24px;

        }

        &>.collected {
            color: #BFC2D1;
            font-size: 22px;
            font-weight: 400;
            line-height: 30px;
            font-style: normal;
            text-align: center;
            margin-top: 70px;
        }

        &>.money {
            font-weight: 800;
            font-size: 42px;
            line-height: 57px;
            text-align: center;
            color: #000000;
            margin-top: 16px;
            margin-bottom: 74px;
        }
    }


}

@media screen and (max-width: 700px) {
    .welcome {
        &>.welcome_logo {
            margin-top: 180px;
        }
        &>.communication {
            font-style: normal;
            font-weight: 900;
            font-size: 32px;
            line-height: 48px;
            text-align: center;
            margin: 0 16px;
        }
    }
}
