.footer {
    display: flex;
    color: #fff;
    padding: 46px 72px;
    background-color: #343239;
    justify-content: space-between;

    & > .left,
    & > .middle,
    & > .right {
        display: flex;
        align-items: center;
    }

    & .left {
        & > div > a {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #ffffff;
            text-decoration: none;
        }

        & > div {
            margin-bottom: 16px;
        }
    }

    & .middle {
        flex-direction: column;
        align-items: flex-start;
        & > div {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #ffffff;
        }

        & > .skip-style {
            font-weight: normal;
        }
    }

    & .right {
        & img {
            width: 30px;
            height: 30px;
        }
    }
}

@media screen and (max-width: 700px) {
    .footer {
        flex-direction: column;
        padding: 24px 16px 16px 16px;
        & .right {
            margin-top: 64px;
            & > div {
                justify-content: flex-start;
            }

            & > .hidden {
                display: none;
            }
        }

        & > .left > div {
            margin-bottom: 16px;
        }
    }
}