.notFound {
    background: url(../../images/notFound.jpg);
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    background-position: center center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: fixed;

    top:0;
    bottom: 0;

    & > .opacity {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #000;
        opacity: 0.33;
    }

    .label {
        font-style: normal;
        font-weight: 900;
        font-size: 132px;
        line-height: 198px;
        color: #FFFFFF;
    }

    & > .text {
        font-size: 64px;
        color: #ffffff;
        line-height: 96px;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
        margin: 0 150px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 5;

        .goBack {
            height: 58px;
            color: #000;
            font-size: 17px;
            margin-top: 33px;
            background-color: #fae526;
            font-weight: 900;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            min-width: 112px;
            padding: 10px;
            border: 0px;
        }
    }
}

@media screen and (max-width: 700px) {
    .notFound {
        & > .text {
            font-style: normal;
            font-weight: 900;
            font-size: 32px;
            line-height: 48px;
            text-align: center;
            margin: 0 16px;
        }
    }
}