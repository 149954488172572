.team {
    padding: 0 72px 0 72px;

    height: 600px;

    &>.title {
        font-style: normal;
        font-weight: 800;
        font-size: 48px;
        line-height: 65px;
        text-align: center;
        color: #000000;
        margin-bottom: 16px;
    }

    &>.description {
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        line-height: 38px;
        text-align: center;
        color: #ACACAC;
    }

    & .membersMobile {
        display: none;
    }

    &>.members,
    &>.membersMobile {
        margin-top: 40px;


        & .member {
            margin-bottom: 24px;

            & .imgHolder {
                overflow: hidden;
                width: 200px;
                height: 200px;

                & img {
                    width: 100%;
                    object-fit: contain;
                }
                margin-bottom:10px;
            }

            & .name,
            & .surname {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #000000;
                text-align: center;
                width: 200px;
            }

            & .role {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: #666564;
                text-align: center;
                width: 200px;
                align-items: center;
                display: flex;
                justify-content: space-around;
            }

            & .social {
                display: flex;
            }

            & .linkedin,
            & .instagram {
                margin-top: 10px;
                cursor: pointer;

                &>img {
                    width: 16px;
                    height: 16px;
                    background-color: #fff;

                }
            }
        }

        &>.member:not(:last-child) {
            margin-right: 15px;
        }
    }
}


@media screen and (max-width: 700px) {
    .team {
        height: auto;
        padding: 24px 16px 16px 16px;

        & .members {
            display:none;
        }

        & .membersMobile {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            &>.member {
                width: 200px;
                display: flex;
                margin-right: 0;
                overflow: hidden;
                margin-bottom: 20px;
                flex-direction: column;
            }

            &>.member:not(:last-child) {
                margin-right: 0;
            }
        }
    }
}